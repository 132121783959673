<template>
	<!-- 解决方案 - 客户管理详情 -->
	<div class="container" v-if="detail">
		<GModule>
			<ul class="detail-box">
				<li>
					<p>项目ID</p>
					<p>{{detail.id}}</p>
				</li>
				<li>
					<p>项目名称</p>
					<p>{{detail.project_name}}</p>
				</li>
				<li>
					<p>签约时间</p>
					<p>{{detail.createtime_text}}</p>
				</li>
				<li>
					<p>客户名称</p>
					<p>{{detail.sale.nickname}}</p>
				</li>
				<li>
					<p>客户标签</p>
					<p>
						<span v-for="(item,index) in detail.sale.labels_arr" :key="index">{{item.name}}</span>
					</p>
				</li>
				<li>
					<p>联系人</p>
					<p>{{detail.sale.linkman}}</p>
				</li>
				<li>
					<p>联系方式</p>
					<p>{{detail.sale.contact}}</p>
				</li>
				<li>
					<p>合同编号</p>
					<p>{{detail.contract_no}}</p>
				</li>
				<li>
					<p>合同金额</p>
					<p>{{detail.contract_money}}</p>
				</li>
				<li>
					<p>追加额</p>
					<p>{{detail.add_amount}}</p>
				</li>
				<li>
					<p>总金额</p>
					<p>{{detail.total_amount}}</p>
				</li>
				<li>
					<p>已收款金额</p>
					<p>{{detail.received_amount}}</p>
				</li>
				<li>
					<p>待付款金额</p>
					<p>{{detail.pending_amount}}</p>
				</li>
				<li>
					<p>已开发票额</p>
					<p>{{detail.invoiced_amount}}</p>
				</li>
				<li>
					<p>备注</p>
					<p>{{detail.remark}}</p>
				</li>
			</ul>
			<a-button class="btn clo6" @click="goBack()">返回</a-button>
			<ul class="add-box">
				<li>
					<div class="title">
						<p>合同收款</p>
						<a-button class="clo1" @click="addContract(detail.id,detail.project_name,detail.contract_money)"><plus-outlined /></a-button>
					</div>
					<a-table bordered :pagination="false" :data-source="data" :columns="columns">
						<template #bodyCell="{ column ,record,index}">
							<template v-if="column.key === 'operation'">
								<div class="caozuo">
									<a-popconfirm
										title="确认是否删除?"
										ok-text="确认"
										cancel-text="取消"
										:disabled="record.isDel"
										@confirm="delBtn(record.id,index,record.paid,0)">
										<p :class="record.isDel?'clo9':''">删除</p>
									</a-popconfirm>
								</div>
							</template>
						</template>
					</a-table>
				</li>
				<li>
					<div class="title">
						<p>追加收款</p>
						<a-button class="clo1" @click="openAdd(detail.id,detail.project_name,detail.add_amount)"><plus-outlined /></a-button>
					</div>
					<a-table bordered :pagination="false" :data-source="data2" :columns="columns2">
						<template #bodyCell="{ column ,record,index}">
							<template v-if="column.key === 'operation'">
								<div class="caozuo">
									<a-popconfirm
										title="确认是否删除?"
										ok-text="确认"
										cancel-text="取消"
										:disabled="record.isDel"
										@confirm="delBtn(record.id,index,record.paid,1)">
										<p :class="record.isDel?'clo9':''">删除</p>
									</a-popconfirm>
								</div>
							</template>
						</template>
					</a-table>
				</li>
				<li>
					<div class="title">
						<p>收款明细</p>
					</div>
					<a-table bordered :pagination="false" :data-source="data3" :columns="columns3">
						<template #bodyCell="{ column ,record}">
							<template v-if="column.dataIndex === 'images'">
								<div style="max-width: 120px;overflow: hidden;">
									<a-image :preview="false" :width="120" :src="record.images" @click="openSwiper(record.images)"/>
								</div>
							</template>
						</template>
					</a-table>
				</li>
			</ul>
		</GModule>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				columns:[{
					title: '期数',
					width: 80,
					dataIndex: 'periods',
					fixed: 'left'
				},{
					title: '应收比例（%）',
					width: 80,
					dataIndex: 'pay_ratio'
				},{
					title: '应收金额',
					width: 80,
					dataIndex: 'pay_money'
				},{
					title: '收款金额',
					width: 80,
					dataIndex: 'paid'
				},{
					title: '待收款金额',
					width: 80,
					dataIndex: 'to_paid'
				},{
					title: '操作',
					key: 'operation',
					fixed: 'right',
					width: 50,
				}],
				data:[],
				columns2:[{
					title: '期数',
					width: 80,
					dataIndex: 'periods',
					fixed: 'left'
				},{
					title: '追加金额',
					width: 80,
					dataIndex: 'pay_money'
				},{
					title: '收款金额',
					width: 80,
					dataIndex: 'paid'
				},{
					title: '待收款金额',
					width: 80,
					dataIndex: 'to_paid'
				},{
					title: '操作',
					key: 'operation',
					fixed: 'right',
					width: 50,
				}],
				data2:[],
				columns3:[{
					title: '期数',
					width: 80,
					dataIndex: 'periods_name',
					fixed: 'left'
				},{
					title: '收款金额',
					width: 80,
					dataIndex: 'pay_amount'
				},{
					title: '收款金额时间',
					width: 80,
					dataIndex: 'pay_time'
				},{
					title: '收款凭证',
					width: 80,
					dataIndex: 'images'
				},{
					title: '备注',
					width: 80,
					dataIndex: 'remark'
				}],
				data3:[],
				detail:null ,// 详情
				id:0 // 详情id
			}
		},
		computed:{
			isAddCollectFresh(){
				return this.$store.state.isAddCollectFresh
			},
			isFresh(){
				return this.$store.state.isFresh
			}
		},
		watch:{
			isAddCollectFresh(val,oldvalue){
				// 刷新追加金额列表
				if(val){
					this.getInfo()
					// 重置
					this.$store.commit('setCollectInfo',{})
					this.$store.commit('setIsAddCollectFresh',0) 
				}
			},
			isFresh(val,oldvalue){
				// 刷新合同收款列表
				if(val){
					this.getInfo()
					// 重置
					this.$store.commit('setCollectInfo',{})
					this.$store.commit('setIsFresh',0) 
				}
			}
		},
		created(){
			this.id = this.$route.query.ids
			this.getInfo()
		},
		methods:{
			async getInfo(){
				// 获取详情
				let res = await this.$utils.api.post({
					url:'/contract/getRow',
					data:{
						ids:this.id
					},
					result:1
				})
				if(res.code == 1){
					this.detail = res.data
					if(res.data.original_details&&res.data.original_details.length > 0){
						let arr = res.data.original_details
						for(let i in arr){
							// 未收款时 可操作删除该列
							if(parseInt(arr[i].paid) > 0){
								arr[i].isDel = true
							}else{
								arr[i].isDel = false
							}
						}
					}
					if(res.data.append_details&&res.data.append_details.length > 0){
						let arr = res.data.append_details
						for(let i in arr){
							// 未收款时 可操作删除该列
							if(parseInt(arr[i].paid) > 0){
								arr[i].isDel = true
							}else{
								arr[i].isDel = false
							}
						}
					}
					this.data = res.data.original_details // 合同收款
					this.data2 = res.data.append_details // 追加收款
					this.data3 = res.data.detailslog //收款明细
				}
			},
			goBack(){
				this.$router.back() // 返回
			},
			openSwiper(img){
				// 弹出图片
				let imgs = []
				imgs.push(img)
				this.$store.commit('setSwiperArr',imgs)
				// 弹出图片列表
				this.$store.commit('setSwiper',1)
			},
			addContract(id,name,money){
				// 合同收款
				this.$store.commit('setCollectInfo',{id:id,name:name,money:money})
				this.$store.commit('setIsAddContract',1)
			},
			openAdd(id,name,money){
				// 弹出追加收款框
				this.$store.commit('setCollectInfo',{id:id,name:name,money:money})
				this.$store.commit('setIsAddCollect',1)
			},
			async delBtn(id,index,paid,type){
				// 删除收款记录
				let res = await this.$utils.api.post({
					url:'/contract/del_periods',
					data:{
						ids:id
					},
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('删除成功')
					if(type == 1){
						this.data2.splice(index,1)
					}else{
						this.data.splice(index,1)
					}
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	.container{
		margin: 20px;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 0px; // 控制滚动条
		}
		&::-webkit-scrollbar-thumb{
			background-color: #bfbfbf; // 滚动条颜色
		}
		&:hover{
			&::-webkit-scrollbar {
				width: 1px;
			}
		}
		.detail-box{
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			li{
				display: flex;
				flex: 1;
				margin: 0 15px;
				p{
					&:nth-child(1){
						width: 90px;
					}
					&:nth-child(2){
						width: 200px;
						font-size: 12px;
						color: #999999;
						padding: 5px 10px;
						background-color: #f5f5f5;
					}
				}
			}
		}
		.add-box{
			list-style: none;
			margin: 0;
			padding: 0;
			li{
				background-color: #f5f5f5;
				padding: 10px;
				margin-top: 20px;
				border-radius: 4px;
				.title{
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 2px solid #fff;
					height: 40px;
					margin-bottom: 10px;
				}
			}
			
		}
		.btn{
			margin: 20px;
		}
	}
	.caozuo{
		display: flex;
		justify-content: space-around;
		align-items: center;
		p{
			margin: 0;
			font-size: 12px;
			color: #407cff;
		}
		.clo9{
			color:#999;
		}
	}
</style>